/*
  * Here we declare our screen sizes for use in styled-components
  * https://jsramblings.com/2018/02/04/styled-components-media-queries.html
*/

const screenSize = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletS: 540,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560
}

const device = Object.keys(screenSize).reduce((acc, cur) => {
  acc[cur] = `(max-width: ${screenSize[cur]}px)`;
  return acc;
}, {});

export default device;
