const breakpoints = ['576px', '768px', '992px', '1200px']

export const colors = {
  blue: '#193684',
  lightBlue: '#4078c0',
  greyBlue: '#756c8f',
  darkBlue: '#043373',
  pink: '#dc869d',
  darkPink: '#ae7f9c',
  red: '#5c2c34',
  white: '#ffffff',
}

const theme = {
  colors,
  breakpoints,
}

export default theme
