import React from 'react'
import Layout from '../../components/layout'
import { graphql } from 'gatsby'
import StyledGatsbyLink from '../../components/StyledGatsbyLink'
import { colors } from '../../css/theme'
// TODO Add summary list to top of post with links to each talk

const BlogPost = ({ data }) => {
  const {
    allMarkdownRemark: { nodes },
  } = data

  const sortEntriesByDate = () => {
    data.allMarkdownRemark.nodes.sort((a, b) => {
      var c = new Date(a.frontmatter.date)
      var d = new Date(b.frontmatter.date)
      return d - c
    })
  }

  sortEntriesByDate()

  return (
    <>
      <Layout>
        {nodes &&
          nodes.map(pageNode => {
            return (
              <StyledGatsbyLink
                to={pageNode.frontmatter.path}
                color={colors.darkBlue}
                key={`${Date.now}-${pageNode.frontmatter.title}`}
              >
                {pageNode.frontmatter.title}
              </StyledGatsbyLink>
            )
          })}
      </Layout>
    </>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        id
        frontmatter {
          title
          path
          date
        }
      }
    }
  }
`
