import styled from 'styled-components'
import device from '../utils/screenSizeConfig'

export const StyledLinkWrapper = styled.ul`
  list-style: none;
  float: right;

  @media ${device.tabletS} {
    float: none;
    margin: 0 auto;
    margin-top: 1rem;
  }
`
