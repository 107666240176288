import React from 'react'
import Header from './header'
import { StyledLayoutWrapper } from '../styledComponents/styledLayoutWrapper'

const Layout = ({ children }) => (
  <StyledLayoutWrapper>
    <span id="top"></span>
    <Header />
    {children}
  </StyledLayoutWrapper>
)

export default Layout;
