import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { colors } from '../css/theme'

const LinkContainer = styled.div`
  margin-bottom: 1rem;

  a {
    color: ${props => (props.color ? props.color : colors.lightBlue)};
    text-decoration: none;
    display: inline-block;
    position: relative;

    &:hover {
      :after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
      }
    }

    &:focus {
      box-shadow: 0 0 0 2px ${colors.blue};
      border-radius: 6px;
      outline: none;
    }

    :after {
      content: '';
      position: absolute;
      width: 100%;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${colors.lightBlue};
      -webkit-transform-origin: bottom right;
      transform-origin: bottom right;
      transition: -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
      transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
      transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1),
        -webkit-transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
    }
  }
`

const StyledGatsbyLink = ({ to, children, color, activeColor }) => (
  <LinkContainer color={color}>
    <Link
      to={to}
      activeStyle={{ color: activeColor ? activeColor : colors.darkBlue }}
    >
      {children}
    </Link>
  </LinkContainer>
)

export default StyledGatsbyLink
