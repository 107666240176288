import styled from 'styled-components'

export const StyledLayoutWrapper = styled.main`
  max-width: 800px;
  margin: 3rem auto;
  padding: 0 1rem;

  a {
    text-shadow: none;
    background-image: none;
  }
`
