import React from 'react'
import { Link } from 'gatsby'
import { StyledLink } from '../styledComponents/styledLink'
import { StyledLinkWrapper } from '../styledComponents/styledLinkWrapper'
import { StyledHeader } from '../styledComponents/styledHeader'
import { StyledPageTitle } from '../styledComponents/styledPageTitle'

const ListLink = props => (
  <StyledLink>
    <Link to={props.to}>{props.children}</Link>
  </StyledLink>
)

const Header = () => (
  <StyledHeader>
    <Link to="/">
      <StyledPageTitle>MunderstandMe</StyledPageTitle>
    </Link>

    <StyledLinkWrapper>
      <ListLink to="/about/">About</ListLink>
      <ListLink to="/contact/">Contact</ListLink>
      <ListLink to="/sandbox/">Sandbox</ListLink>
      <ListLink to="/blog/">Blog</ListLink>
    </StyledLinkWrapper>
  </StyledHeader>
)

export default Header;
